import React, { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Card,
  Placeholder,
  SelectField,
  CheckboxField,
  Tabs,
  TabItem,
} from "@aws-amplify/ui-react";

import listMatchNotes from "../../api/listMatchNotes";
import FieldsSection from "../FieldsSection";
import StringArrayField from "../StringArrayField";
import { selectOptions } from "../../constants";
import MatchDelete from "../MatchDelete";
import NoteList from "../NoteList";
import Metadata from "../Metadata";

const MatchFields = ({
  isLoading,
  match,
  updateMatch,
  handlePairedFieldsChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTabChange = (i) => {
    setSearchParams((current) => {
      current.set("tab", i);

      return current;
    });
  };

  useEffect(() => {
    if (!["0", "1"].includes(searchParams.get("tab"))) {
      handleTabChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const associatedApplication = match.user.applications.items.find(
    (app) => app.id === match.applicationId
  );

  return (
    <Card variation="elevated">
      <div className="form">
        <section className="basic-info">
          <h2 className="w-fit mt-4 px-2 text-xl font-bold bg-blue-100">
            {match.jobOpportunity.title} Job Opportunity
          </h2>
          <h2 className="w-fit mt-4 px-2 text-xl font-bold bg-blue-100">+</h2>
          <h2 className="w-fit mt-4 mb-8 px-2 text-xl font-bold bg-blue-100">
            {associatedApplication
              ? associatedApplication.jobType.title
              : "No Associated"}{" "}
            Application
          </h2>
          {[
            "createdAt",
            "creator",
            "updatedAt",
            "updater",
            "systemModTimestamp",
            "systemProcessName",
          ].map((field) => (
            <div className="mt-2" key={field}>
              <p className="font-semibold">{field}</p>
              <div className="h-6">
                {isLoading ? (
                  <Placeholder className="mt-2" />
                ) : (
                  String(match[field])
                )}
              </div>
            </div>
          ))}
        </section>
        <div className="grow overflow-x-auto">
          <Tabs
            currentIndex={+searchParams.get("tab")}
            onChange={(i) => handleTabChange(i)}
            spacing="equal"
          >
            <TabItem title="Common">
              <FieldsSection
                entity="match"
                heading="Information"
                fields={{
                  availableStartDate: match.availableStartDate,
                  isAccepted: (
                    <CheckboxField
                      checked={match.isAccepted || false}
                      labelHidden
                      isDisabled
                    />
                  ),
                  status: (
                    <SelectField
                      value={match.status}
                      onChange={(event) =>
                        updateMatch("status", event.target.value)
                      }
                      placeholder="null"
                      options={selectOptions.matchStatus}
                      labelHidden
                    />
                  ),
                  reasonForRejection: (
                    <StringArrayField
                      values={match.reasonForRejection}
                      options={selectOptions.reasonForRejection}
                      updateField={(newValue) =>
                        updateMatch("reasonForRejection", newValue)
                      }
                    />
                  ),
                  rejectionByCustomer: match.rejectionByCustomer,
                  moreInfoRequest: (
                    <StringArrayField
                      values={match.moreInfoRequest}
                      options={selectOptions.moreInfoRequest}
                      updateField={(newValue) =>
                        updateMatch("moreInfoRequest", newValue)
                      }
                    />
                  ),
                  moreInfoRequestMessage: match.moreInfoRequestMessage,
                  freelancerPitch: match.freelancerPitch,
                }}
                updateData={updateMatch}
              />
              <FieldsSection
                heading="References"
                fields={{
                  jobOpportunity: (
                    <Link to={`/job-opportunities/${match.jobOpportunityId}`}>
                      <span className="border-b-2 border-blue-200">
                        {match.jobOpportunity.title}
                      </span>
                    </Link>
                  ),
                  application: associatedApplication ? (
                    <Link to={`/applications/${associatedApplication.id}`}>
                      <span className="border-b-2 border-blue-200">
                        {match.user?.username || match.user?.identity_username}
                        's Application
                      </span>
                    </Link>
                  ) : (
                    <span className="text-gray-400">
                      No associated application
                    </span>
                  ),
                }}
              />
              <FieldsSection
                heading="Start and End Dates (Applicable after hiring)"
                entity="match"
                fields={{
                  startDate: match.startDate,
                  endDate: match.endDate,
                }}
                updateData={updateMatch}
              />

              <FieldsSection
                heading="Customer Rate"
                entity="match"
                fields={{
                  "customerRate.currency": match.customerRate?.currency,
                  "customerRate.value": match.customerRate?.value,
                }}
                updateData={handlePairedFieldsChange}
                pairedFieldsKey="customerRate"
              />
              <FieldsSection
                heading="Rate"
                entity="match"
                fields={{
                  "rate.currency": match.rate?.currency,
                  "rate.value": match.rate?.value,
                }}
                updateData={handlePairedFieldsChange}
                pairedFieldsKey="rate"
              />
              <Metadata
                metadata={match.metadata}
                updateData={updateMatch}
                newKeyOptions={
                  JSON.parse(
                    process.env.REACT_APP_PREDEFINED_METADATA_KEYS ?? "{}"
                  ).Match
                }
              />
              <FieldsSection
                heading="Admin"
                className="mt-10 p-4 bg-red-50"
                subheading="Admin options provide advanced configuration, that should be changed with caution"
                fields={{
                  disableNotification: (
                    <CheckboxField
                      checked={match.disableNotification || false}
                      onChange={(event) =>
                        updateMatch("disableNotification", event.target.checked)
                      }
                      labelHidden
                    />
                  ),
                }}
              />
              <MatchDelete
                applicationId={match.applicationId}
                jobOpportunityId={match.jobOpportunityId}
              />
            </TabItem>
            <TabItem title="Notes">
              <NoteList
                loadNotes={() =>
                  listMatchNotes({
                    jobOpportunityId: match.jobOpportunityId,
                    applicationId: match.applicationId,
                  })
                }
                createNotePayload={{
                  applicationId: match.applicationId,
                  jobOpportunityId: match.jobOpportunityId,
                }}
              />
            </TabItem>
          </Tabs>
        </div>
      </div>
    </Card>
  );
};

export default MatchFields;
