import { useState, useEffect, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { cloneDeep, set } from "lodash-es";

import { AlertContext } from "../context/Alert";
import getPropertyValue from "../helpers/getPropertyValue";
import setPropertyValue from "../helpers/setPropertyValue";
import deletePropertyValue from "../helpers/deletePropertyValue";
import { selectFields } from "../constants";

const mapEntityToUrlParameter = {
  jobRole: "job-roles",
};

const useEditing = (pathParam, getData, mutateData, entity) => {
  const navigate = useNavigate();
  const [rawData, setRawData] = useState({});
  const [data, setData] = useState({});
  const [mutationPayload, setMutationPayload] = useState({});
  const [isLoading, setIsLoading] = useState(null);
  const [isNotFound, setIsNotFound] = useState(false);
  const { showGraphQLError, showAlert } = useContext(AlertContext);

  const load = async (searchValue) => {
    try {
      setIsLoading(true);
      setIsNotFound(false);

      const response = await getData(searchValue);

      if (!response.data.fields || response.data.fields.items?.length === 0) {
        setIsNotFound(true);

        return;
      }

      if (entity === "user" && pathParam.length !== 36) {
        setRawData(cloneDeep(response.data.fields.items[0]));
        setData(response.data.fields.items[0]);
      } else {
        setRawData(cloneDeep(response.data.fields));
        setData(response.data.fields);
      }
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const mutate = async () => {
    try {
      setIsLoading(true);

      const response = await mutateData(data.id || pathParam, mutationPayload);

      if (pathParam === "create") {
        showAlert("Creation is succesful", "success");
        navigate(
          `/${mapEntityToUrlParameter[entity]}/${response.data.fields.id}`
        );

        return;
      }

      showAlert("Update is successful", "success");
      load(pathParam);
      setMutationPayload({});
    } catch (error) {
      setIsLoading(false);
      showGraphQLError(error?.errors);
    }
  };

  useEffect(() => {
    if (pathParam !== "create") {
      load(pathParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathParam]);

  const updateData = useCallback(
    (path, value) => {
      const setStateCallback = (prevObject) => {
        if (selectFields[entity].includes(path) && value === "") {
          value = null;
        }

        const newObject = { ...prevObject };

        setPropertyValue(path, newObject, value);

        return newObject;
      };

      setData(setStateCallback);
      setMutationPayload((currentPayload) => {
        const payload = setStateCallback(currentPayload);
        const initialPropertyValue = getPropertyValue(path, rawData);

        if (
          initialPropertyValue === value ||
          (initialPropertyValue === null && value === "")
        ) {
          deletePropertyValue(path, payload);
        }

        return payload;
      });
    },
    [rawData, entity]
  );

  const handlePairedFieldsChange = (key, newValue, pairedFieldsKey) => {
    const newPairedFieldsValue = { ...data[pairedFieldsKey] };

    set(newPairedFieldsValue, key.split(".").slice(1), newValue);

    updateData(pairedFieldsKey, newPairedFieldsValue);
  };

  const handleReset = () => {
    if (!Object.keys(mutationPayload).length) {
      return;
    }

    setData(cloneDeep(rawData));
    setMutationPayload({});
    showAlert("Fields are reset", "success");
  };

  const handleSubmit = () => {
    mutate();
  };

  return {
    isLoading,
    isNotFound,
    data,
    updateData,
    handlePairedFieldsChange,
    handleReset,
    handleSubmit,
  };
};

export default useEditing;
