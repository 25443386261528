import { SelectField, CheckboxField } from "@aws-amplify/ui-react";

import FieldsSection from "../FieldsSection";
import KnownLanguages from "../KnownLanguages";
import Skills from "../Skills";
import Badges from "../Badges";
import Assessments from "../Assessments";
import { selectOptions } from "../../constants";

const FreelancerFields = ({
  user,
  updateUser,
  handlePairedFieldsChange,
  badgesObject: { userBadges, refreshBadges, isLoading },
}) => {
  return (
    <>
      <FieldsSection
        entity="user"
        fields={{
          availability: (
            <SelectField
              value={user.availability}
              onChange={(event) =>
                updateUser("availability", event.target.value)
              }
              placeholder="null"
              options={selectOptions.availability}
              labelHidden
            />
          ),
        }}
        updateData={updateUser}
      />
      <FieldsSection
        entity="user"
        heading="Phone"
        fields={{
          "phone.number": user.phone?.number,
          "phone.whatsAppAllowed": (
            <CheckboxField
              checked={user.phone?.whatsAppAllowed || false}
              onChange={(event) =>
                handlePairedFieldsChange(
                  "phone.whatsAppAllowed",
                  event.target.checked,
                  "phone"
                )
              }
              labelHidden
            />
          ),
        }}
        updateData={handlePairedFieldsChange}
        pairedFieldsKey="phone"
      />
      <FieldsSection
        entity="user"
        heading="Freelancer Partner Details"
        fields={{
          talentSource: user.talentSource,
          additionalMarkup: user.additionalMarkup ?? 1.0,
        }}
        updateData={updateUser}
      />
      <Assessments userId={user.id} userEmail={user.email} />
      <Badges
        badges={userBadges || []}
        refreshBadges={refreshBadges}
        isLoading={isLoading}
        userId={user.id}
      />
      <Skills skills={user.skills || []} updateSkills={updateUser} />
      <FieldsSection
        heading="Rate per hour"
        entity="user"
        fields={{
          "ratePerHour.value": user.ratePerHour?.value,
          "ratePerHour.currency": user.ratePerHour?.currency,
        }}
        updateData={handlePairedFieldsChange}
        pairedFieldsKey="ratePerHour"
      />
      <KnownLanguages languages={user.knownLanguages} updateData={updateUser} />
    </>
  );
};

export default FreelancerFields;
