import { useState, useContext } from "react";
import { Button } from "@aws-amplify/ui-react";

import { AlertContext } from "../../context/Alert";
import Loader from "../Loader";
import createApplication from "../../api/createApplication";
import mutateApplication from "../../api/mutateApplication";
import JobRoleSelect from "../JobRoleSelect";

const ApplicationCreate = ({ applications, userId }) => {
  const [chosenJobRole, setChosenJobRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { showGraphQLError } = useContext(AlertContext);

  const applyUser = async () => {
    try {
      setIsLoading(true);

      const application = applications.find(
        (app) => chosenJobRole === app.jobType.id
      );

      if (application) {
        await mutateApplication(application.id, { isNotActive: false });
      } else {
        await createApplication(chosenJobRole, userId);
      }

      window.location.reload();
    } catch (error) {
      showGraphQLError(error.errors);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="flex items-end justify-between gap-x-4 mt-10 p-0.5 [&>div:first-child]:grow">
        <JobRoleSelect
          chosenJobRole={chosenJobRole}
          setChosenJobRole={setChosenJobRole}
          filterCallback={(jobRole) =>
            !applications.some(
              (app) => jobRole.id === app.jobType.id && !app.isNotActive
            ) && jobRole.isActive
          }
        />
        <Button size="small" onClick={applyUser} isDisabled={!chosenJobRole}>
          Apply to Job Role
        </Button>
      </div>
    </>
  );
};

export default ApplicationCreate;
